import "./Missions.css";
import React, { useState } from "react";
import { BarLoader } from "react-spinners";
import { images } from "../../../Assets/assetsHelper";
import { Box, Typography } from "@mui/material";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";

function Missions({ navigate, loading, missions }) {
  return (
    <div className="mainContainer">
      <div className="cont">
        <div style={{ width: "100%", textAlign: "center" }}>
          <img
            alt="Logo"
            crossOrigin={"anonymous"}
            style={{ width: "150px" }}
            src={images.Company_Logo}
          />
          <Typography
            variant={"h2"}
            sx={{
              fontSize: { xs: "18px", md: "30px" },
              margin: "0 0 10px 0",
              textAlign: "center",
            }}
          >
            Welcome to the Code Challenge Judging System! We're glad to have you
            with us. Thank you for your dedication and commitment.
          </Typography>
        </div>
        {!loading ? (
          <>
            <Typography
              variant={"h3"}
              sx={{
                fontSize: { xs: "20px", md: "25px" },
                margin: "10px 0",
                textAlign: "start",
                fontWeight: "700",
              }}
            >
              Your Missions:
            </Typography>
            {missions?.length ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                  flexWrap: { xs: "wrap", md: "noWrap" },
                  marginTop: "20px",
                  justifyContent: "space-between",
                }}
              >
                {missions.map((mission) => (
                  <>
                    <Box
                      sx={{
                        width: "fit-content",
                        backgroundColor: "white",
                        padding: { xs: "10px", md: "20px" },
                        borderRadius: "10px",
                        width: { xs: "100%", md: "100%" },
                        fontSize: "23px",
                        fontWeight: "700",
                        textAlign: "center",
                        "&:hover": {
                          boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.15)",
                        },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant={"h5"}
                        sx={{
                          fontSize: { xs: "15px", md: "25px" },
                        }}
                      >
                        {mission?.name}
                      </Typography>
                      <Typography
                        variant={"h5"}
                        sx={{
                          fontSize: { xs: "13px", md: "20px" },
                        }}
                      >
                        <strong>Category:</strong> {mission?.categorypName}
                      </Typography>
                      <Typography
                        variant={"h5"}
                        sx={{
                          fontSize: { xs: "13px", md: "20px" },
                        }}
                      >
                        <strong>Age Group:</strong> {mission?.ageGroupName}
                      </Typography>
                      <Typography
                        variant={"h5"}
                        sx={{
                          fontSize: { xs: "13px", md: "20px" },
                        }}
                      >
                        <strong>Status:</strong>{" "}
                        {mission?.missionJudgingIsCompleted
                          ? "Finished"
                          : mission?.status == "started"
                          ? "Started"
                          : mission?.status == "paused"
                          ? "Paused"
                          : mission?.status == "completed"
                          ? "Completed"
                          : "Not Started"}
                      </Typography>
                      <PrimaryButton
                        classBtn={"center"}
                        text={"see more"}
                        style={{
                          textTransform: "capitalize",
                          borderRadius: "5px",
                          color: "var(--text-secondary)",
                          backgroundColor: "var(--primary)",
                          height: "30px",
                          padding: "0 10px",
                          minWidth: "100px",
                          textWrap: "noWrap",
                          marginTop: "10px",
                        }}
                        click={() =>
                          navigate(`/missions/${mission.missionJudgingId}`)
                        }
                      />
                    </Box>
                  </>
                ))}
              </Box>
            ) : (
              <Typography
                variant={"h5"}
                sx={{
                  fontSize: { xs: "15px", md: "22px" },
                  textAlign: "center",
                  padding: "30px 0",
                }}
              >
                No Missions Assigned yet!!
              </Typography>
            )}
          </>
        ) : (
          <div className="loadingBox">
            <BarLoader color="#6a0505" />
          </div>
        )}
      </div>
    </div>
  );
}

export default Missions;
