import React, { useContext, useEffect, useState } from "react";
import { userContext } from "../Contexts/userContext";
import useFetch from "../Hooks/useFetch";
import { missionsURL } from "../Hooks/URL";
import { useNavigate } from "react-router";
import Missions from "../Templates/Home/Missions/Missions";

function MissionsPage() {
  const navigate = useNavigate();
  const { contextData, setContextData } = useContext(userContext);

  const [missions, setMissions] = useState([]);
  const { data, loading, error } = useFetch(missionsURL);

  useEffect(() => {
    if (!loading && !error && data) {
      setMissions(data.data);
    }
  }, [data, loading, error]);

  useEffect(() => {
    setContextData({ ...contextData, page: "missions" });
  }, []);

  return (
    <Missions
      contextData={contextData}
      loading={loading}
      missions={missions}
      navigate={navigate}
    />
  );
}

export default MissionsPage;
