import axios from "axios";
import { useEffect, useReducer, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Fetch } from "./Fetch";

const initialState = {
  dataRes: [],
  loadingRes: true,
  errorRes: null,
};

const ACTION = {
  API_REQUEST: "api-request",
  FETCH_DATA: "fetch-data",
  ERROR: "error",
};

function reducer(state, { type, payload }) {
  switch (type) {
    case ACTION.API_REQUEST:
      return { ...state, loadingRes: true, errorRes: null };
    case ACTION.FETCH_DATA:
      return { ...state, dataRes: payload, loadingRes: false };
    case ACTION.ERROR:
      return { ...state, loadingRes: false, errorRes: payload };
    default:
      return state;
  }
}
function useDelete(url, go, page, setNewData, FetchUrl, newObject) {
  const navigate = useNavigate();
  const [cookies] = useCookies(["TOKEN"]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (go) {
      setLoading(true);
      dispatch({ type: ACTION.API_REQUEST });
      axios
        .delete(url, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/app.js",
            // Authorization: `Bearer ${cookies.TOKEN}`,
          },
          withCredentials: true,
          data: newObject,
        })
        .then((res) => {
          dispatch({ type: ACTION.FETCH_DATA, payload: res.data });
          setLoading(false);
          toast.success("Deleted Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          if (setNewData) Fetch(FetchUrl ?? url, setNewData, navigate);
          if (page) navigate(`/${page}`);
        })
        .catch((err) => {
          dispatch({ type: ACTION.ERROR, payload: err.error });
          setLoading(false);
          toast.error(err.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            draggable: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
    }
  }, [go]);
  return { state, loading };
}

export default useDelete;
