import React, { createContext, useEffect, useState } from "react";
import Home from "../Templates/Home/Home";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useJwt } from "react-jwt";
import { userContext } from "../Contexts/userContext";
import { Fetch } from "../Hooks/Fetch";
import { userInfo } from "../Hooks/URL";

function HomePage() {
  const [cookies] = useCookies(["TOKEN"]);
  const navigate = useNavigate();

  const { decodedToken, isExpired } = useJwt(cookies.TOKEN);
  const [user, setUser] = useState("");
  const [contextData, setContextData] = useState({
    page: "",
    role: "",
    data: "",
    link: "",
    user: "",
  });

  useEffect(() => {
    if (user?.name) {
      setContextData({ ...contextData, user: user });
    }
  }, [user]);

  // useEffect(() => {
  //   if (!cookies) {
  //     navigate("/login");
  //   }
  // }, [cookies]);

  useEffect(() => {
    Fetch(userInfo, { function: setUser }, navigate);
  }, []);

  return (
    <userContext.Provider value={{ contextData, setContextData }}>
      <Home />
    </userContext.Provider>
  );
}

export default HomePage;
