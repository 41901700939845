import React, { useContext, useEffect, useState } from "react";
import useFetch from "../Hooks/useFetch";
import useDelete from "../Hooks/useDelete";
import { missionJudgingURL, missionsURL } from "../Hooks/URL";
import { useNavigate, useParams } from "react-router-dom";
import { userContext } from "../Contexts/userContext";
import useSave from "../Hooks/useSave";
import useFetchWhen from "../Hooks/useFetchWhen";
import { toast } from "react-toastify";
import JudgingReport from "../Templates/Home/Missions/JudgingReport/JudgingReport";

function JudgingReportPage() {
  const navigate = useNavigate();
  const { contextData, setContextData } = useContext(userContext);
  let { id, teamId } = useParams();

  const url = `${missionJudgingURL}/${id}/teams/${teamId}/forEvaluate`;
  const judgingURL = `${missionJudgingURL}/${id}/teams/${teamId}/scoringReport`;

  const [report, setReport] = useState({});
  // const [mission, setMission] = useState({});
  // const {
  //   data: missionData,
  //   loading: missionLoading,
  //   error: missionError,
  // } = useFetch(url);
  const { data, loading, error } = useFetch(judgingURL);

  useEffect(() => {
    if (!loading && !error && data) {
      setReport(data?.data);
    }
  }, [data, loading, error]);

  // useEffect(() => {
  //   if (!missionLoading && !missionError && missionData) {
  //     setMission(missionData?.data);
  //   }
  // }, [missionData, missionLoading, missionError]);

  return (
    <JudgingReport
      role={contextData?.role}
      url={url}
      loading={loading}
      report={report}
      // mission={mission}
    />
  );
}

export default JudgingReportPage;
