import "./Login.css";
import React from "react";
import { useTranslation } from "react-i18next";
import { images } from "../../../Assets/assetsHelper";
import LoginForm from "../../../Components/Organisms/Forms/LoginForm/LoginForm";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

function Login() {
  const [t, i18n] = useTranslation("common");
  return (
    <div className="Login">
      <div className="container">
        <div className="loginIconCont">
          <AccountCircleIcon className="loginIcon" />
        </div>
        <LoginForm />
      </div>
    </div>
  );
}

export default Login;
