import axios from "axios";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

export const Fetch = async (url, setNewData, navigate) => {
  if (localStorage.getItem("isLogged") == "true") {
    if (url) {
      try {
        const res = await axios(
          setNewData?.limit
            ? `${url}?page=${setNewData?.page + 1}&limit=${setNewData?.limit}${
                setNewData?.search ? `&search=${setNewData?.search}` : ""
              }${setNewData?.query ?? ""}`
            : url,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/app.js",
              // Authorization: `Bearer ${cookies.TOKEN}`,
            },
            withCredentials: true,
          }
        );
        setNewData?.function(res?.data?.data);
      } catch (err) {
        if (err?.response?.status == 401) {
          navigate("/login");
          localStorage.setItem("isLogged", false);
        } else {
          toast.error(err.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  } else {
    navigate("/login");
  }
};
