import React, { useContext, useEffect, useState } from "react";
import useFetch from "../Hooks/useFetch";
import useDelete from "../Hooks/useDelete";
import { missionJudgingURL, missionsURL } from "../Hooks/URL";
import { useNavigate, useParams } from "react-router-dom";
import { userContext } from "../Contexts/userContext";
import useSave from "../Hooks/useSave";
import useFetchWhen from "../Hooks/useFetchWhen";
import Missions from "../Templates/Home/Missions/MissionDetails/MissionDetails";
import MissionDetails from "../Templates/Home/Missions/MissionDetails/MissionDetails";

function MissionDetailsPage() {
  const { contextData, setContextData } = useContext(userContext);
  let { id, categoryId } = useParams();
  const navigate = useNavigate();

  const url = `${missionJudgingURL}/${id}`;
  const teamsUrl = `${missionJudgingURL}/${id}/teams`;

  const [goSave, setGoSave] = useState(false);
  const [Open, setOpen] = useState(false);
  // const [mission, setMission] = useState({});
  const [teams, setTeams] = useState([]);
  const [teamID, setTeamID] = useState("");
  // const { data, loading, error } = useFetch(url);
  const {
    data: teamsData,
    loading: teamsLoading,
    error: teamsError,
  } = useFetch(teamsUrl);
  const {
    data: teamData,
    loading: teamLoading,
    error: teamError,
  } = useFetchWhen(`${teamsUrl}/${teamID}/details`, teamID);

  // useEffect(() => {
  //   if (!loading && !error && data) {
  //     setMission(data?.data);
  //   }
  // }, [data, loading, error]);

  useEffect(() => {
    if (!teamsLoading && !teamsError && teamsData) {
      setTeams(teamsData?.data);
    }
  }, [teamsData, teamsLoading, teamsError]);

  const { saveState, loading: addLoading } = useSave({
    url: `${url}/complete`,
    goSave,
    setGoSave,
  });

  if (saveState.dataRes.status == "success") {
    navigate(`/`);
  }

  return (
    <MissionDetails
      setTeamID={setTeamID}
      role={contextData?.role}
      url={url}
      loading={teamsLoading}
      teams={teams}
      id={id}
      setGoSave={setGoSave}
      addLoading={addLoading}
      setOpen={setOpen}
      Open={Open}
      teamData={teamData?.data}
      teamLoading={teamLoading}
    />
  );
}

export default MissionDetailsPage;
