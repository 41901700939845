import "./NavBar.css";
import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { images } from "../../../../Assets/assetsHelper";
import MenuIcon from "@mui/icons-material/Menu";
import { BaseURL, logoutURL } from "../../../../Hooks/URL";
import useLogout from "../../../../Hooks/useLogout";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import { userContext } from "../../../../Contexts/userContext";
import SideListItem from "../../../Molecules/SideListItem/SideListItem";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import PaidIcon from "@mui/icons-material/Paid";
import Man4Icon from "@mui/icons-material/Man4";
import { Drawer, Typography } from "@mui/material";
import SummarizeIcon from "@mui/icons-material/Summarize";

function NavBar({ setShowMenu }) {
  const navigate = useNavigate();
  const { contextData, setContextData } = useContext(userContext);

  const [goSave, setGoSave] = useState(false);
  const { saveState } = useLogout({
    url: logoutURL,
    goSave,
    setGoSave,
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [DrawerOpen, setDrawerOpen] = useState(false);
  const handleDrawer = (bool) => {
    setDrawerOpen(bool);
  };

  return (
    <div className="nav">
      <ul className="TabList">
        <SideListItem
          name={"home"}
          Pathname={"/"}
          title={`${contextData?.user?.name ?? ""} Missions` ?? "Username"}
          // icon={<AccountCircleIcon className="ListIcon" />}
          setShowMenu={setShowMenu}
          customClass={"mobile"}
        />
        <SideListItem
          name={""}
          Pathname={"/"}
          title="Missions"
          icon={<DashboardRoundedIcon className="ListIcon" />}
          setShowMenu={setShowMenu}
          customClass={"desktop"}
        />
      </ul>
      <div className="rightNav">
        {/* <div className="sidebarIcon" onClick={() => handleDrawer(true)}>
          <span className={"menuIcon"}>
            <MenuIcon
              sx={{ fontSize: "27px", cursor: "pointer", marginTop: "5px" }}
            />
          </span>
        </div> */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <span
            className="desktop"
            style={{
              marginRight: "10px",
              cursor: "pointer",
              color: "#6a0505",
            }}
            onClick={() => navigate("/")}
          >
            {contextData?.user?.name ?? "Username"}
          </span>
          <LogoutIcon
            style={{
              fontSize: "40px",
              color: "#6a0505",
              marginLeft: "10px",
              cursor: "pointer",
            }}
            className="navIcon"
            onClick={() => setGoSave(true)}
          />
        </div>
      </div>
      <Drawer
        open={DrawerOpen}
        onClose={() => handleDrawer(false)}
        className="drawer"
      >
        <Typography
          className="NavLinks"
          style={{ marginBottom: "40px", marginTop: "20px", fontSize: "20px" }}
        >
          {contextData?.user?.name ?? "Username"} Account
        </Typography>
        <Typography
          className="NavLinks"
          onClick={() => {
            navigate("/");
            handleDrawer(false);
          }}
        >
          Missions
        </Typography>
      </Drawer>
    </div>
  );
}

export default NavBar;
