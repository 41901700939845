import { images } from "../../../../Assets/assetsHelper";
import "./MissionDetails.css";
import { BarLoader } from "react-spinners";
import { useNavigate } from "react-router";
import { display, fontSize } from "@mui/system";
import { Box, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import FormModal from "../../../../Components/Molecules/FormModal/FormModal";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&:first-child": {
    borderRadius: "5px 0 0 0",
  },
  "&:last-child": {
    borderRadius: "0 5px 0 0",
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#6a0505",
    color: theme.palette.common.white,
    fontSize: "15px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const columns = [
  {
    id: "teamId",
    label: "ID",
    minWidth: 50,
  },
  {
    id: "name",
    label: "Team Name",
    minWidth: 100,
  },
  {
    id: "evaluated",
    label: "Evaluated",
    minWidth: 100,
    align: "center",
  },
];

function MissionDetails({
  loading,
  url,
  teams,
  id,
  setGoSave,
  addLoading,
  Open,
  setOpen,
  setTeamID,
  teamData,
  teamLoading,
}) {
  const navigate = useNavigate();
  return (
    <div className="mainContainer">
      <FormModal
        Open={Open}
        HandleClose={() => setOpen(false)}
        XButton={true}
        Form={
          <>
            {!teamLoading ? (
              <>
                <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
                  <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                    <div>
                      <h4>Team Name: </h4>
                      <h3>{teamData?.name}</h3>
                    </div>
                  </div>
                  <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                    <div>
                      <h4>Team Code: </h4>
                      <h3>{teamData?.teamId}</h3>
                    </div>
                  </div>
                  <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                    <div>
                      <h4>Category: </h4>
                      <h3>{teamData?.categoryName}</h3>
                    </div>
                  </div>
                  <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                    <div>
                      <h4>Age Group: </h4>
                      <h3>{teamData?.ageGroupName}</h3>
                    </div>
                  </div>
                  <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                    <div>
                      <h4>Coach Name: </h4>
                      <h3>{teamData?.coach?.name}</h3>
                    </div>
                  </div>
                </Box>
                <div style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "column" },
                      width: "100%",
                    }}
                  >
                    <h4 style={{ marginTop: "20px" }}>Team Members:</h4>
                    <TableContainer
                      component={Paper}
                      sx={{
                        marginTop: "10px",
                      }}
                    >
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="center">
                              name
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Birthday
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Age
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              National ID
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {teamData?.students?.map((stu) => (
                            <StyledTableRow key={stu.name}>
                              <StyledTableCell align="center">
                                {stu.name}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {stu?.birthday?.split("T")?.at(0)}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {stu?.age}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {stu?.nationalId}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </div>
              </>
            ) : (
              <div className="loadingBox">
                <BarLoader color="#6a0505" />
              </div>
            )}
          </>
        }
      />
      {teams?.mission && !loading ? (
        <div className="Cont">
          <div className="greyBox">
            <Box sx={{ width: "100%", display: "flex", flexWrap: "wrap" }}>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Mission Name: </h4>
                  <h3>{teams?.mission?.name}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Mission Number: </h4>
                  <h3>{teams?.mission?.order}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Cateogry: </h4>
                  <h3>{teams?.mission?.categorypName}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Age Group: </h4>
                  <h3>{teams?.mission?.ageGroupName}</h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Status: </h4>
                  <h3>
                    {teams?.mission?.status == "started"
                      ? "Started"
                      : teams?.mission?.status == "paused"
                      ? "Paused"
                      : teams?.mission?.status == "completed"
                      ? "Completed"
                      : "Not Started"}
                  </h3>
                </div>
              </div>
              <div className="greyNumberBox" style={{ minWidth: "200px" }}>
                <div>
                  <h4>Completed: </h4>
                  <h3>
                    {teams?.mission?.missionJudgingIsCompleted
                      ? "Completed"
                      : "Not completed"}
                  </h3>
                </div>
              </div>
              {teams?.teams
                ?.map((item) => item?.evaluated)
                ?.every((mis) => mis == 1) &&
              teams?.teams?.length &&
              !teams?.mission?.missionJudgingIsCompleted ? (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                  }}
                >
                  <PrimaryButton
                    classBtn={"center"}
                    text={"Complete Mission"}
                    loading={addLoading}
                    style={{
                      textTransform: "capitalize",
                      borderRadius: "5px",
                      color: "var(--text-secondary)",
                      backgroundColor: "var(--primary)",
                      height: "35px",
                      padding: "0 15px",
                      minWidth: "100px",
                      textWrap: "noWrap",
                    }}
                    click={() => setGoSave(true)}
                  />
                </Box>
              ) : (
                ""
              )}
            </Box>
          </div>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "0px",
            }}
          >
            <Typography
              variant={"h3"}
              sx={{
                fontSize: { xs: "20px", md: "25px" },
                margin: "10px 0",
                textAlign: "start",
                fontWeight: "700",
              }}
            >
              Teams:
            </Typography>
          </Box>
          <Paper
            className="tableCont"
            sx={{ width: "fit-content", minWidth: "100%" }}
          >
            {!loading ? (
              <>
                <TableContainer>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <StyledTableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                            }}
                          >
                            {column.label}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {teams?.teams?.map((team) => {
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={team.id}
                            style={{ cursor: "pointer" }}
                          >
                            {columns.map((column) => {
                              const value = team[column.id];
                              return (
                                <TableCell
                                  key={column.id}
                                  align={column.align}
                                  style={{ padding: "10px" }}
                                >
                                  {column.id == "evaluated" ? (
                                    teams?.mission?.status == "started" ||
                                    teams?.mission?.status == "completed" ||
                                    (teams?.mission?.status == "paused" &&
                                      value) ? (
                                      value ? (
                                        <>
                                          {/* <PrimaryButton
                                            classBtn={"center small"}
                                            text={"Show Report"}
                                            style={{
                                              textTransform: "capitalize",
                                              borderRadius: "5px",
                                              color: "var(--text-secondary)",
                                              backgroundColor: "var(--primary)",
                                              height: "30px",
                                              padding: "0 10px",
                                              minWidth: "100px",
                                              textWrap: "noWrap",
                                              fontSize: "10px",
                                            }}
                                            click={() =>
                                              navigate(
                                                `/missions/${id}/teams/${team?.id}/report`
                                              )
                                            }
                                          /> */}
                                          <span
                                            onClick={() =>
                                              navigate(
                                                `/missions/${id}/teams/${team?.id}/report`
                                              )
                                            }
                                            style={{
                                              textDecoration: "underline",
                                              cursor: "pointer",
                                            }}
                                          >
                                            Show Report
                                          </span>
                                        </>
                                      ) : (
                                        <PrimaryButton
                                          classBtn={"center small"}
                                          text={"Evaluate Now"}
                                          style={{
                                            textTransform: "capitalize",
                                            borderRadius: "5px",
                                            color: "var(--text-secondary)",
                                            backgroundColor: "var(--primary)",
                                            height: "30px",
                                            padding: "0 10px",
                                            minWidth: "100px",
                                            textWrap: "noWrap",
                                            fontSize: "10px",
                                          }}
                                          click={() =>
                                            navigate(
                                              `/missions/${id}/teams/${team?.id}`
                                            )
                                          }
                                        />
                                      )
                                    ) : (
                                      `-`
                                    )
                                  ) : column.id == "name" ? (
                                    <span
                                      onClick={() => {
                                        setTeamID(team?.id);
                                        setOpen(true);
                                      }}
                                      style={{
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {value}
                                    </span>
                                  ) : (
                                    value ?? "-"
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                  {!teams?.teams?.length ? (
                    <h3 style={{ textAlign: "center", margin: "50px" }}>
                      No teams Added Yet!!
                    </h3>
                  ) : (
                    ""
                  )}
                </TableContainer>
                {/* <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={pageCount * rowsPerPage}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                /> */}
              </>
            ) : (
              <div className="loadingBox">
                <BarLoader color="#6a0505" />
              </div>
            )}
          </Paper>
        </div>
      ) : (
        <div className="loadingBox">
          <BarLoader color="#6a0505" />
        </div>
      )}
    </div>
  );
}

export default MissionDetails;
